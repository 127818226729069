<nav [ngClass]="showNavbar ? 'fixed-top' : ''">
    <mat-toolbar class="navbar">
        <h2 class="pt-1 mb-0 text-white on-hover" routerLink="/">DxProductions</h2>
    </mat-toolbar>
</nav>
<mat-toolbar *ngIf="showNavbar" style="height: 5vh;">
</mat-toolbar>
<!-- Header -->
<div class="image-box" [ngStyle]="{'background': currentStyle.color}">
    <div class="container">
        <div class="row" data-aos="fade-down">
            <div class="col-12 col-md-6" style="display: flex; align-items: flex-end;">
                <div class="row p-4">
                    <img class="p-0 col-auto icon" src="{{currentExperience?.icon}}" width="100px" height="100px">
                    <h1 class="col brand-white" style="display: flex; align-items: flex-end;" data-aos="zoom-in">
                        {{currentExperience?.title}}
                    </h1>
                </div>
            </div>
            <div *ngIf="!isMobile" class="col col-md-6 p-2">
                <div class="glass-card">
                    <div class="card-content">
                        <h4 class="brand-white">Overview</h4>
                        <div class="row">
                            <div class="col-6">
                                <h6 class="brand-white">Roles</h6>
                                <div class="mb-2">
                                    <p class="brand-white"
                                        *ngFor="let role of currentExperience?.overview.roles; let i = index">{{role}}
                                    </p>
                                </div>
                            </div>
                            <div class="col-6">
                                <div *ngIf="currentExperience?.overview.startDate">
                                    <h6 class="brand-white">Duration</h6>
                                    <p class="brand-white mb-2">{{currentExperience?.overview.startDate | date:'MMM
                                        yyyy'}} -
                                        <span *ngIf="currentExperience?.overview.endDate">
                                            {{currentExperience?.overview.endDate | date:'MMM yyyy'}}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div class="col-12">
                                <h6 class="brand-white">Tech Stack</h6>
                                <mat-chip-list aria-label="Technologies">
                                    <mat-chip *ngFor="let tech of currentExperience.overview.stack">{{tech}}</mat-chip>
                                </mat-chip-list>
                            </div>
                        </div>
                        <div class="row mt-4"
                            *ngIf="currentExperience?.overview.link || currentExperience?.overview.download || currentExperience?.overview.repo">
                            <div class="col-6" *ngIf="currentExperience?.overview.link">
                                <button class="square-btn" (click)="openUrl(currentExperience?.overview.link)">
                                    View website
                                </button>
                            </div>
                            <div class="col-6" *ngIf="currentExperience?.overview.download">
                                <button class="square-btn" (click)="openUrl(currentExperience?.overview.download)">
                                    Download
                                </button>
                            </div>
                            <div class="col-6" *ngIf="currentExperience?.overview.repo">
                                <button class="square-btn" (click)="openUrl(currentExperience?.overview.repo)">
                                    View Github
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Details and images -->
<div class="detail-box">
    <div class="container pt-4">
        <div class="row" data-aos="fade-down">
            <div *ngIf="isMobile" class="col-12 col-md-4">
                <div class="card mb-4">
                    <div class="card-content">
                        <h4>Overview</h4>
                        <p [innerHTML]="currentExperience.overview.description"></p>
                        <h6 class="mt-4">Roles</h6>
                        <div class="mb-2">
                            <p>
                                <span *ngFor="let role of currentExperience?.overview.roles; let i = index">
                                    <span *ngIf="i > 0">,</span> {{role}}
                                </span>
                            </p>
                        </div>
                        <div *ngIf="currentExperience?.overview.startDate">
                            <h6>Duration</h6>
                            <p class="mb-2">{{currentExperience?.overview.startDate | date:'MMM yyyy'}} -
                                <span *ngIf="currentExperience?.overview.endDate">
                                    {{currentExperience?.overview.endDate | date:'MMM yyyy'}}
                                </span>
                            </p>
                        </div>
                        <h6>Tech Stack</h6>
                        <mat-chip-list aria-label="Technologies">
                            <mat-chip *ngFor="let tech of currentExperience.overview.stack">{{tech}}</mat-chip>
                        </mat-chip-list>
                        <div class="mt-4" *ngIf="currentExperience?.overview.link">
                            <button class="square-btn" (click)="openUrl(currentExperience?.overview.link)">
                                View website
                            </button>
                        </div>
                        <div class="mt-4" *ngIf="currentExperience?.overview.download">
                            <button class="square-btn" (click)="openUrl(currentExperience?.overview.download)">
                                Download
                            </button>
                        </div>
                        <div class="mt-4" *ngIf="currentExperience?.overview.repo">
                            <button class="square-btn" (click)="openUrl(currentExperience?.overview.repo)">
                                View Github
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-8 order-2 mb-4"
                [ngClass]="currentExperience?.platform == 'web' ? 'order-1 col-md-12' : 'order-1 col-md-8'">
                <div class="card mb-4" *ngIf="!isMobile">
                    <div class="card-content">
                        <p [innerHTML]="currentExperience.overview.description"></p>
                    </div>
                </div>
                <div class="card mb-4" *ngFor="let info of currentExperience.infoParagraphs; let i = index">
                    <div class="card-content">
                        <h4>{{info.header}}</h4>
                        <div class="row">
                            <p class="col" [innerHTML]="info.text"></p>
                            <img *ngIf="info.image" class="col-12 col-md-4 mt-2 slide-image" [src]="info.image"
                                (click)="openModal(1, i)">
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="currentExperience?.images" class="col-12 mb-4"
                [ngClass]="currentExperience?.platform == 'mobile' ? 'order-3 col-md-4' : 'order-3 col-md-12'">
                <div class="card">
                    <div class="card-content">
                        <h4>Screenshots/Photos</h4>
                        <div class="image-carousel">
                            <carousel-component [carouselImages]="currentExperience?.images"
                                (activeSlideEvent)="setActiveSlide($event)"></carousel-component>
                            <img class="slide-image mt-2" [src]="activeSlide" (click)="openModal(100, activeSlideId)">
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="currentExperience?.videos" class="col-12 mb-4 order-4">
                <div class="card">
                    <div class="card-content">
                        <h4>Videos</h4>
                        <div class="row">
                            <div class="col-12 col-md-6" *ngFor="let videos of currentExperience?.videos; let i = index">
                                <iframe width="100%" height="315" [src]="safeVideoUrls[i]" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Other projects -->
<div class="other-box">
    <div class="container pt-4 pb-4">
        <h4 style="color: black;">Other projects</h4>
        <div class="row mt-4 mb-4">
            <div class="col-6 col-md-2 item-box mb-3" *ngFor="let experience of otherExperiences; let i = index">
                <img class="image-icon" src="{{experience.icon}}" width="100px" height="100px"
                    (click)="openPage(experience.id)">
            </div>
        </div>
    </div>
</div>