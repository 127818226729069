import { NgModule } from "@angular/core";
import { DetailsPageComponent } from "./detailspage.component";
import { SharedModule } from "src/app/shared/shared.module";
import { ExperienceData } from "src/app/constants/experience";

@NgModule({
    declarations: [
        DetailsPageComponent
    ],
    imports: [
        SharedModule
    ],
    providers: [
        ExperienceData
    ]
})

export class DetailsPageModule {
}