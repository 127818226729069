import { Component, HostListener, Inject, isDevMode, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { ButtonsConfig, ButtonsStrategy, ButtonType, Image, ImageModalEvent, LoadingType, ModalGalleryConfig, ModalGalleryRef, ModalGalleryService } from '@ks89/angular-modal-gallery';
import { ExperienceData } from 'src/app/constants/experience';
import { Experience } from 'src/app/models/experience';

@Component({
  selector: 'experience-details',
  templateUrl: './detailspage.component.html',
  styleUrls: ['./detailspage.component.scss']
})
export class DetailsPageComponent implements OnInit {
  isMobile = false;
  showNavbar = true;

  activeSlideId = 0;
  activeSlide = '';
  safeVideoUrls = []

  experienceId = null;
  allExperiences = null;
  otherExperiences = null;
  currentExperience: Experience = null;
  currentStyle = {
    'background': 'linear-gradient(rgba(0, 0, 0, 0.3), rgba(47, 47, 162, 0.1)), url(/assets/demonth.png) center center',
    'background-repeat': 'no-repeat',
    'background-size': 'cover',
    'color': 'white'
  };

  styleObject(): Object {
    var defaultValues = {
      'background': 'linear-gradient(rgba(0, 0, 0, 0.3), rgba(47, 47, 162, 0.1)), url(/assets/demonth.png) center center',
      'background-repeat': 'no-repeat',
      'background-size': 'cover',
      'color': 'white'
    }
    return defaultValues
  }

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private dataRoute: ActivatedRoute,
    private expData: ExperienceData,
    private dialog: MatDialog,
    private modalGalleryService: ModalGalleryService
  ) { this.onResize() }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    const screenWidth = window.innerWidth;
    this.isMobile = screenWidth <= 480
  }

  ngOnInit(): void {
    if (this.experienceId == null) {
      this.experienceId = this.dataRoute.snapshot.paramMap.get('id')
    }

    if (this.experienceId == null || this.experienceId == 'undefined') {
      if (isDevMode()) {
        console.log('id null', this.experienceId);
      }
      this.router.navigate(['/']);
      return;
    }

    this.getExperience()
  }

  getExperience() {
    this.allExperiences = this.expData.all
    this.otherExperiences = new Array()
    this.allExperiences.forEach(item => {
      if (item.id != this.experienceId) {
        this.otherExperiences.push(item)
      }
    });
    this.currentExperience = this.allExperiences.find(x => x.id === this.experienceId)
    this.currentStyle = {
      'background': "linear-gradient(rgba(0, 0, 0, 0.3), rgba(47, 47, 162, 0.2)), url(" + this.currentExperience.mainImage + ") no-repeat center center",
      'background-repeat': 'no-repeat',
      'background-size': 'cover',
      'color': this.currentExperience.color
    }

    if (this.currentExperience.videos) {
      for (let index = 0; index < this.currentExperience.videos.length; index++) {
        const videoId = this.currentExperience.videos[index];
        this.safeVideoUrls.push(this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube-nocookie.com/embed/' + videoId))
      }
    }
  }

  setActiveSlide(image: any) {
    this.activeSlideId = image.id
    this.activeSlide = image.url
  }

  openModal(id: number, imageIndex: number): void {
    let images: Image[] = [];
    if (id == 100) {
      for (let index = 0; index < this.currentExperience.images.length; index++) {
        const image = this.currentExperience.images[index];
        images.push(
          new Image(index, {
            img: image,
            extUrl: 'http://localhost:4200' + image
          }))
      }
    } else {
      const info = this.currentExperience.infoParagraphs[imageIndex];
      images.push(
        new Image(imageIndex, {
          img: info.image,
          extUrl: 'http://localhost:4200' + info.image
        }))
    }

    console.log(id, images, imageIndex)

    this.showNavbar = false
    const dialogRef: ModalGalleryRef = this.modalGalleryService.open({
      id: id,
      images: images,
      currentImage: images[0],
      libConfig: {
        // buttonsConfig: this.customButtonsConfig,
        // currentImageConfig: {
        //   loadingConfig: {
        //     enable: false,
        //     type: LoadingType.STANDARD
        //   }
        // },
        previewConfig: {
          visible: false
        }
      },
    } as ModalGalleryConfig) as ModalGalleryRef;

    dialogRef.close$.subscribe((event: ImageModalEvent) => {
      this.showNavbar = true
    });
  }

  openPage(id: string) {
    this.router.navigate(['/experience/' + id]);
    this.experienceId = id;
    this.getExperience()
  }

  getYTUrl(index: number) {
    if (this.currentExperience.videos) {
      return this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube-nocookie.com/embed/' + this.currentExperience.videos[index])
    } else {
      return '';
    }
  }

  openUrl(url: string) {
    window.open(url, '_blank');
  }
}