import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { RedditChatData } from 'src/app/constants/reddit-chats';

@Component({
  selector: 'app-reddit-chat',
  templateUrl: './reddit-chat.component.html',
  styleUrls: ['./reddit-chat.component.scss']
})
export class RedditChatComponent implements OnInit {
    dataSource;
    resultsLength = 0;

    displayedColumns: string[] = ['created', 'username', 'message']
    @ViewChild('activePaginator', { static: true }) paginator: MatPaginator;

    constructor(private chatData: RedditChatData) { }

    ngOnInit(): void {
        //this.resultsLength = this.chatData.messages.length
        this.dataSource = new MatTableDataSource(this.chatData.messages)
        this.dataSource.paginator = this.paginator
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }
}