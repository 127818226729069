<div class="container">
    <div class="row">
        <div class="col-12">
            <mat-form-field>
                <mat-label>Search</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Search by date, username, message">
            </mat-form-field>
        </div>
    </div>

    <mat-table [dataSource]="dataSource" matSort class="block">

        <ng-container matColumnDef="created">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Date </mat-header-cell>
            <mat-cell *matCellDef="let chat" data-label="Date"> {{chat.created_at | date:'dd-MM-yy hh:mm'}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="username">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Username </mat-header-cell>
            <mat-cell *matCellDef="let chat" data-label="Username"> {{chat.username}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="message">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Message </mat-header-cell>
            <mat-cell *matCellDef="let chat" data-label="Message"> {{chat.message}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <mat-paginator #activePaginator [length]="resultsLength" [pageSizeOptions]="[50, 100, 200]"></mat-paginator>
</div>