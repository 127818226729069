<div class="row ml-1 mr-1 d-flex justify-content-center">
    <div *ngIf="visibleSlides.length >1" class="col-auto carousel-arrow p-0">
        <button mat-icon-button (click)="carouselChange(false)">
            <i class="fas fa-chevron-left"></i>
          </button>
    </div>
    <div class="col row carousel">
        <div class="col w-100 d-flex justify-content-center" *ngFor="let slide of visibleSlides; let i = index">
            <img class="carousel-select" [src]="slide" (click)="passActiveSlide(i, slide)">
        </div>
    </div>
    <div *ngIf="visibleSlides.length > 1" class="col-auto carousel-arrow p-0">
        <button mat-icon-button (click)="carouselChange(true)">
            <i class="fas fa-chevron-right"></i>
        </button>
    </div>
</div>