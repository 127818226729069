<div class="container">
    <div class="card p-3">
        <div class="row">
            <div class="col-12 col-lg-3">
                <h3>About Me</h3>
            </div>
            <div class="col-12 col-lg-9">
                <p>
                    Hello, I am a junior developer based in Amsterdam, the Netherlands. Born in Amsterdam with Surinamese/Indonesian roots. My passion lies in development and currently web development with Angular. I also have an eye for UI/UX experience for the customer.
                </p>
                <br>
                <p>
                    If you have an idea you want to realise, feel free to contact me, I would love to work together with you.
                </p>
            </div>
        </div>
    </div>
</div>