import { NgModule } from "@angular/core";
import { RedditChatComponent } from './reddit-chat.component';
import { SharedModule } from "src/app/shared/shared.module";
import { RedditChatData } from "src/app/constants/reddit-chats";

@NgModule({
    declarations: [
        RedditChatComponent
    ],
    imports: [
        SharedModule
    ],
    providers: [
        RedditChatData
    ]
})

export class RedditChatModule {
}