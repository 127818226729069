<div class="footer">
    <div class="container">
        <div class="row">
            <!-- Contact -->
            <div class="col-12 col-md-3">
                <h4 style="text-transform: uppercase;">Contact</h4>
                <p>Feel free to contact me.</p>
            </div>
            <!-- Links -->
            <div class="col-12 col-md-9">
                <div class="row">
                    <i class="col-12 col-md-3 link far fa-envelope fa-3x" aria-hidden="true" (click)="openLink('mailto:donavan.asmawidjaja@outlook.com')"></i>
                    <i class="col-12 col-md-3 link fab fa-linkedin fa-3x" aria-hidden="true" (click)="openLink('https://www.linkedin.com/in/donnaxlb')"></i>
                    <i class="col-12 col-md-3 link fab fa-github fa-3x" aria-hidden="true" (click)="openLink('https://github.com/DonnaxNL')"></i>
                    <div class="col-12 col-md-3 pointer" (click)="openLink('https://www.fiverr.com/dxproductionsnl')">
                        <img src="https://iconape.com/wp-content/png_logo_vector/fiverr.png" height="40px" width="40px">
                    </div>
                </div>
            </div>
            <!-- Copy -->
            <div class="col-12 text-center">
                <div class="divider"></div>
                &copy; {{year | date:"yyyy"}} DxProductions
            </div>
        </div>
    </div>
</div>