import { Component, EventEmitter, HostListener, Input, OnInit, Output } from "@angular/core";

@Component({
    selector: 'carousel-component',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
    isMobile = false;
    images = [];
    visibleSlides = [];
    slidesShown = 10;
    slidePosition = 0;

    @Input() set carouselImages(images: Array<string>) {
        this.images = images
        this.ngOnInit()
        this.onResize()
    };

    @Output() activeSlideEvent = new EventEmitter<any>();

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.isMobile = window.innerWidth <= 480
        if (window.innerWidth <= 580) {
            this.slidesShown = 2
        } else if (window.innerWidth <= 780) {
            this.slidesShown = 4
        } else if (window.innerWidth <= 1000) {
            this.slidesShown = 6
        } else {
            this.slidesShown = 10
        }
        this.ngOnInit()
    }

    ngOnInit(): void {
        let size = this.images.length < this.slidesShown ? this.images.length : this.slidesShown
        this.visibleSlides = []
        for (let index = 0; index < size; index++) {
            const imageSource = this.images[index];
            this.visibleSlides.push(imageSource)
            if (index == 0) {
                this.passActiveSlide(0, imageSource)
            }
        }
    }

    carouselChange(forward: boolean) {
        this.visibleSlides = []
        let size = this.images.length < this.slidesShown ? this.images.length : this.slidesShown
        if (forward) {
            this.slidePosition = this.slidePosition + 1
        } else {
            if (this.slidePosition > 0) {
                this.slidePosition = this.slidePosition - 1
            } else if (this.slidePosition == 0) {
                this.slidePosition = this.images.length
            }
        }
        for (let index = this.slidePosition; index < size + this.slidePosition; index++) {
            const element = this.images[index % this.images.length];
            this.visibleSlides.push(element)
        }
    }

    passActiveSlide(id: number, url: string) {
        this.activeSlideEvent.emit({ id: id, url: url });
    }
}