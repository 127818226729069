import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  year = new Date();

  constructor() { }

  ngOnInit(): void { }

  openLink(url: string) {
    window.open(url, '_blank');
  }

}
